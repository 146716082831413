.before-after__container {
  width: 100%;

  margin-top: -40px;
}

.preview {
  background-color: #f9f9f9;
  padding-top: 8px;
}

.preview__header {
  width: 90%;

  margin: 0 auto;

  padding: 12px;

  border-radius: 8px;
  border: 1px solid #f2f2f2;

  background-color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preview__label-before {
  width: 50%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.preview__label-after {
  width: 50%;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

  color: #ff8c80;
}

.preview__images {
  width: 100%;
  min-height: 318px;

  position: relative;
  overflow: hidden;
}

.preview__image {
  width: 50%;
}

.preview__arrows {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.info {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  background-color: #fff;
  padding: 16px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.before-after-item {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-bottom: 0;
}

.before-after-item__name {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #696969;
}

.before-after-item__value {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #171717;
}

.before-after-item__level {
  display: flex;
  gap: 4px;

  margin-top: 16px;
}

.before-after-item__level-item {
  width: 33.3%;
  height: 6px;
  border-radius: 1px;

  background-color: #f2f2f2;
}

.before-after-item__level-item.active {
  background-color: #ff8c80;
}

.devider {
  width: 1px;
  background-color: #f2f2f2;
  border-radius: 1px;
}
