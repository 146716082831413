.profile__container {
  height: 100%;

  max-width: 458px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.profile__content {
  width: 100%;
  flex: 1 0;
}

.profile__title {
  margin-bottom: 16px;
}

.profile__card {
  display: flex;
  flex-direction: column;
  gap: 6px;

  min-width: 288px;
  width: 100%;

  padding: 20px 16px;

  position: relative;

  border: 1px solid #f2f2f2;
  border-radius: 12px;

  overflow: hidden;

  margin-bottom: 16px;
}

.profile__image {
  position: absolute;

  z-index: -1;

  height: 100%;
  top: 0;
  right: -20px;
}

.profile__background {
  width: 275px;
  height: 275px;
  border-radius: 50%;
  background-color: #ff8c80;
  z-index: -2;
  position: absolute;

  top: 10%;
  right: -100px;
}

.profile__item {
  text-align: left;
}

.profile-item__name {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;

  color: #696969;
}

.profile-item__value {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;

  display: flex;
  align-items: center;
}
