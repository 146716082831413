.thanks__container {
  width: 100%;

  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.thanks__image {
  width: 100vw;
  margin-left: -16px;
}

.thanks__title {
  text-align: center;
}
