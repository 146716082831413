.full-height-layout {
  height: 100%;
  min-height: 100dvh;
  width: 100%;
}

.full-height-layout__content {
  height: calc(100dvh - 64px);
  padding: 16px;

  display: flex;
  justify-content: center;
}
