.tooltip__container {
  position: relative;
  display: inline-block;
  height: 24px;
  width: 24px;
}

.tooltip__button {
  outline: none;
  border: none;
  border-radius: 100%;
  background-color: transparent;

  width: 24px;
  height: 24px;

  padding: 0;
}

.tooltip__image {
  width: 100%;
  height: 100%;
}

.tooltip__box {
  position: absolute;
  left: -256%;
  top: -50%;
  width: 264px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 16px;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1000;
  margin-top: 8px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  animation: tooltip-fade 0.3s ease;
}

.tooltip__container button {
  cursor: pointer;
}

.tooltip__container .tooltip__box {
  opacity: 1;
  visibility: visible;
}

@keyframes tooltip-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
