.chart2__container {
  height: 100%;

  max-width: 458px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.chart__container {
  background-color: #f9f9f9;
  padding: 20px 16px;
  border-radius: 16px;

  display: flex;

  position: relative;
}

.chart__view {
  width: 100%;

  position: relative;
}

.chart2__container .chart__y-labels {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #696969;

  position: absolute;
  top: 8px;
  left: 42px;
}

.chart2__container .chart__x-labels {
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #696969;

  padding-top: 8px;
  padding-left: 16px;
}

.chart2__title {
  margin-bottom: 16px;
}

.chart__tooltip1 {
  position: absolute;
  top: -4px;
  right: 58px;

  padding: 4px 8px;

  border-radius: 4px;

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #fff;
  background-color: #ff8c80;
}

.chart__tooltip2 {
  position: absolute;
  bottom: 84px;
  left: 28px;

  padding: 8px 12px;

  border-radius: 4px;

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  background-color: #ffffff;
}
