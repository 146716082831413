.header__container {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;

  Button {
    width: 50%;
  }
}

.header__timer {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: #ff8c80;
  width: 50%;
  text-align: center;
}

.header__timer-text {
  display: flex;
  justify-content: center;
  gap: 28px;

  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  color: #696969;
}

.plan-options__options {
  display: flex;
  flex-direction: column;
  gap: 12px;

  margin-bottom: 32px;
}

.option {
  padding: 12px 16px;

  background-color: #f9f9f9;
  border: 1px solid #f2f2f2;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;

  transition: all 0.3s ease-in;
}

.option.selected {
  border-color: #ff8c80;
  background-color: #fff;
}

.option__main {
  display: flex;
  gap: 12px;
  align-items: center;
}

.option__checkbox {
  width: 24px;
  height: 24px;
  padding: 3px;

  border: 2px solid #9e9e9e;
  border-radius: 50%;
}

.option__name {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 8px;
}

.option__discount {
  display: flex;
  gap: 2px;
}

.option__discount-old-price,
.option__price-old-price {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;

  color: #696969;

  text-decoration: line-through;
}

.option__discount-new-price {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.option__price {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;

  color: #696969;
}

.option__price-new-price {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.plan-options__container Button {
  margin-bottom: 12px;
}

.plan-options__disclamer {
  padding: 16px;

  color: #696969;

  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}
