.review {
  background-color: #f9f9f9;

  text-align: left;
  border-radius: 8px;

  padding: 20px 16px;
}

.review__header {
  margin-bottom: 20px;
}

.review__score {
  margin-bottom: 8px;
}

.review__user {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  color: #696969;
}

.review__label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.review__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
