.button {
  min-height: 56px;
  height: 56px;
  width: 100%;
  max-width: 458px;
  background-color: #ff8c80;
  color: #fff;
  border-radius: 8px;

  border: none;
  outline: none;

  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  font-family: 'Montserrat', sans-serif;
}

.button:disabled {
  background-color: #f2f2f2;
}
