.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;

  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  border-bottom: 1px solid #f2f2f2;
}
