.faq__container {
  padding: 0 12px;
}

.faq__title {
  margin-bottom: 24px;
}

.faq__items {
  display: flex;
  flex-direction: column;

  gap: 12px;
}

.ant-collapse-header-text {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px;
  text-align: left;
}

.ant-collapse-content-box {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #696969;
}
