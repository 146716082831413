.email__container {
  height: 100%;

  max-width: 458px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.email__title {
  margin-bottom: 16px;
}

.email__input {
  height: 56px;
  margin-bottom: 12px;
}

.email__disclamer {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

  color: #696969;
}

.email__link {
  color: #ff8c80;
}
