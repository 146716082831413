.highlights__container {
  padding: 0 16px;
}

.highlights__title {
  margin-bottom: 24px;
}

.highlights__items {
  display: flex;
  flex-direction: column;

  gap: 24px;
}

.highlights-item {
  display: flex;
  gap: 16px;
}

.highlights-item__name {
  color: #171717;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  margin-bottom: 4px;
}

.highlights-item__value {
  color: #696969;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
