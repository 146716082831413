.loader__container {
  height: 100%;

  max-width: 458px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.loader__content {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
}

.loader__text-1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  width: 100%;

  color: #696969;

  padding-bottom: 20px;
  border-bottom: 1px solid #f2f2f2;

  margin-bottom: 16px;
}

.loader__text-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  width: 100%;

  color: #696969;

  margin-bottom: 16px;
}

.loader__title {
  margin-bottom: 8px;
}

.carousel {
  width: 320px;

  margin-bottom: 32px;
}

.review__container {
  padding: 8px;
}
