.chart1__container {
  height: 100%;

  max-width: 458px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.chart__container {
  background-color: #f9f9f9;
  padding: 20px 16px;
  border-radius: 16px;

  display: flex;
}

.chart__view {
  width: 100%;

  position: relative;
}

.chart__y-labels {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #696969;
  padding-bottom: 28px;
}

.chart__x-labels {
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #696969;

  padding-top: 8px;
  padding-left: 16px;
}

.chart1__title {
  margin-bottom: 16px;
}

.chart1__text {
  text-align: left;
  margin-bottom: 16px;
}

.chart1__text .text-accent {
  font-weight: 600;
}

.chart__tooltip {
  position: absolute;
  top: -14px;
  right: 30px;

  padding: 4px 8px;

  border-radius: 4px;

  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: #fff;
  background-color: #ff8c80;
}
