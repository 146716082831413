.name__container {
  height: 100%;

  max-width: 458px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.name__content {
  width: 100%;
}

.name__title {
  margin-bottom: 16px;
}

.name__input {
  height: 56px;
}
