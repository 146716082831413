.plan-options__container {
  padding: 0 16px;
}

.plan-options__title {
  padding-bottom: 24px;
}

.benefit {
  display: flex;
  gap: 16px;

  text-align: left;

  margin-bottom: 24px;
}

.benefit__name {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #696969;
}

.benefit__value {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.option {
  display: flex;
}

.plan-options__container Button {
  width: 100%;
}
