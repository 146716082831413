.phone-screen__container {
  display: flex;
  flex-direction: column;
}

.phone-screen__title {
  padding: 20px 16px;

  background-color: #ff8c80;
  color: #fff;

  text-align: center;
}

.phone-screen__image {
  width: 100%;
}
