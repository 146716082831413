.main__container {
  max-width: 458px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 40px;

  margin: 0 auto;
}
