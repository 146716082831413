.reviews__container {
  padding: 0 12px;
}

.reviews__title {
  margin-bottom: 24px;
}

.reviews__info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-bottom: 12px;
}

.reviews__rating--big {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;

  color: #171717;
}

.reviews__rating {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;

  color: #696969;
}

.reviews__number {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;

  color: #696969;
}

.reviews__items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
